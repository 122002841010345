import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { formatTestId } from 'features/Connect/utils/connectUtils';
import { baseLabelStyles } from '../ModalFormOptions/utils/styles';

export const CheckboxOption = ({ option, selectedOption, handleChange, dataTestId }) => {
  return (
    <CheckboxOptionLabel
      data-testid={`${dataTestId}-${formatTestId(option.value)}-option-label`}
      key={option.value}
      value={option.value}
      control={
        <Checkbox
          data-testid={`${dataTestId}-${formatTestId(option.value)}-option-checkbox`}
          checked={selectedOption === option.value}
          onChange={handleChange}
          value={option.value}
        />
      }
      label={option.label}
    />
  );
};

const CheckboxOptionLabel = styled(FormControlLabel)(({ theme }) => ({
  ...baseLabelStyles(theme),
}));
