import { Box, Container, styled } from '@mui/material';
import { CandidateMatchDashboardPaginationHeader } from './CandidateMatchDashboardPaginationHeader';
import { CandidateMatchCard } from 'features/Connect/components/MatchCard/CandidateMatchCard';
import { ConnectCandidateMatchDashboardDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useEffect, useState } from 'react';
import { useConnectContext } from '../../Context/ConnectContextProvider';
import CandidateMatchingAPI from 'features/Connect/api/candidateMatchingAPI';
import { MatchActionsResponse } from 'types/connectTypes';
import auth from 'utils/auth';
import { ConnectMatchingAPIViewTypes } from 'features/Connect/api/constants';
import { RejectMatchModal } from 'features/Connect/components/Modals/RejectMatchModal/RejectMatchModal';
import { AlreadyConnectedModal } from 'features/Connect/components/Modals/AlreadyConnectedModal/AlreadyConnectedModal';
import { AcceptMatchModal } from 'features/Connect/components/Modals/AcceptMatchModal/AcceptMatchModal';
import { InAppMatchMessagingModal } from 'features/Connect/components/Modals/InAppMatchMessagingModal/InAppMatchMessagingModal';
import { CandidateDashboardViews } from '../../../../CandidatePortal/CandidateDashboardViews/CandidateDashboardViews';
import { ViewState } from '../../../../CandidatePortal/CandidateDashboardViews/utils/constants';

export const CandidateMatchDashboard: React.FC = () => {
  const user = auth.currentUser;
  const { connectContextActions, candidateMatches } = useConnectContext();
  const [totalPendingMatches, setTotalPendingMatches] = useState(0);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [isRejectMatchModalOpen, setIsRejectMatchModalOpen] = useState(false);
  const [isAlreadyConnectedModalOpen, setIsAlreadyConnectedModalOpen] = useState(false);
  const [isAcceptMatchModalOpen, setIsAcceptMatchModalOpen] = useState(false);
  const [isInAppMatchMessagingModalOpen, setIsInAppMatchMessagingModalOpen] = useState(false);
  const [isEndOfMatches, setIsEndOfMatches] = useState(false);
  const [isNoPendingMatches, setIsNoPendingMatches] = useState(false);
  // const [isNoLongerActive, setIsNoLongerActive] = useState(false);

  useEffect(() => {
    const fetchMatches = async () => {
      connectContextActions.setIsLoading(true);
      try {
        const matches: MatchActionsResponse = await CandidateMatchingAPI.getMatches(
          ConnectMatchingAPIViewTypes.FULL
        );
        connectContextActions.setCandidateMatches(matches);
        setTotalPendingMatches(matches.pending.length);
      } finally {
        connectContextActions.setIsLoading(false);
      }
    };
    fetchMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const pendingMatches = candidateMatches?.pending || [];
  const currentMatch = pendingMatches[currentMatchIndex];

  const handlePageChange = (newIndex: number) => {
    setCurrentMatchIndex(newIndex - 1);
  };

  useEffect(() => {
    if (totalPendingMatches === 0) {
      setIsNoPendingMatches(true);
      setIsEndOfMatches(false);
    } else if (currentMatchIndex >= totalPendingMatches) {
      setIsEndOfMatches(true);
      setIsNoPendingMatches(false);
    } else {
      setIsEndOfMatches(false);
      setIsNoPendingMatches(false);
    }
  }, [currentMatchIndex, totalPendingMatches]);

  return (
    <MatchDashboardContainer
      data-testid={ConnectCandidateMatchDashboardDataTestIds.DASHBOARD_CONTAINER}
    >
      <MatchDashboardHeaderContainer
        data-testid={ConnectCandidateMatchDashboardDataTestIds.PAGINATION_HEADER_CONTAINER}
      >
        <CandidateMatchDashboardPaginationHeader
          onChange={handlePageChange}
          currentMatch={currentMatchIndex + 1}
          totalMatches={totalPendingMatches === 0 ? 0 : totalPendingMatches}
          schoolName={currentMatch?.school?.name || ''}
        />
      </MatchDashboardHeaderContainer>
      {currentMatch && !isEndOfMatches && (
        <MatchDashboardBodyContainer
          data-testid={ConnectCandidateMatchDashboardDataTestIds.BODY_CONTAINER}
        >
          <CandidateMatchCard
            school={currentMatch.school}
            principal={currentMatch.principal}
            principalMessage={currentMatch.school_action_message}
            preferenceMatches={currentMatch.preference_matches}
            setIsRejectMatchModalOpen={setIsRejectMatchModalOpen}
            setIsAlreadyConnectedModalOpen={setIsAlreadyConnectedModalOpen}
            setIsAcceptMatchModalOpen={setIsAcceptMatchModalOpen}
          />
        </MatchDashboardBodyContainer>
      )}
      {isNoPendingMatches && <CandidateDashboardViews viewState={ViewState.NO_PENDING_MATCHES} />}
      {isEndOfMatches && <CandidateDashboardViews viewState={ViewState.END_OF_MATCHES} />}
      {/* {isNoLongerActive && <CandidateDashboardViews viewState={ViewState.NO_LONGER_ACTIVE} />} */}
      <RejectMatchModal
        isOpen={isRejectMatchModalOpen}
        onClose={() => setIsRejectMatchModalOpen(false)}
      />
      <AcceptMatchModal
        isOpen={isAcceptMatchModalOpen}
        onClose={() => setIsAcceptMatchModalOpen(false)}
        openInAppMessagingModal={() => setIsInAppMatchMessagingModalOpen(true)}
      />
      <AlreadyConnectedModal
        isOpen={isAlreadyConnectedModalOpen}
        onClose={() => setIsAlreadyConnectedModalOpen(false)}
      />
      <InAppMatchMessagingModal
        isOpen={isInAppMatchMessagingModalOpen}
        school={currentMatch?.school}
        principal={currentMatch?.principal}
        principalMessage={currentMatch?.school_action_message}
        onClose={() => setIsInAppMatchMessagingModalOpen(false)}
      />
    </MatchDashboardContainer>
  );
};

const MatchDashboardContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  height: '100%',
  width: '100%',
  maxWidth: '1040px',
  padding: `${theme.spacing(3)} 0`,
}));

const MatchDashboardHeaderContainer = styled(Box)({
  width: '100%',
  padding: '0px',
});

const MatchDashboardBodyContainer = styled(Box)({
  width: '100%',
});
