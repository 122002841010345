import { Box } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CandidateAndSubmitterProps } from '../types';
import { SubmittedBy, SubmittedByTitle } from './styles';

export const CandidateAndSubmitter: React.FC<CandidateAndSubmitterProps> = ({
  completionText,
  isComplete,
  submittedBy,
}) => {
  return (
    <Box>
      <SubmittedByTitle
        data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.SUBMITTER_NAME}
      >
        {submittedBy.name}
      </SubmittedByTitle>
      {!isComplete && (
        <SubmittedBy data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.COMPLETION_TEXT}>
          {completionText}
        </SubmittedBy>
      )}
    </Box>
  );
};
