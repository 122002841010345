import { useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { PaginationWithArrowsProps } from './types';
import {
  PaginationButtonGroupContainer,
  PaginationButtonIconStyles,
  PaginationButtonStyles,
  PaginationLocationText,
  PaginationLocationTextContainer,
} from './styles';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
export const MobilePaginationButtonGroup: React.FC<PaginationWithArrowsProps> = ({
  totalPages,
  currentPage,
  onChange,
  dataTestId,
  isDisabled,
}) => {
  const { isLoading } = useConnectContext();
  const theme = useTheme();
  const handlePrev = () => {
    if (currentPage > 1) onChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onChange(currentPage + 1);
  };

  return (
    <PaginationButtonGroupContainer data-testid={dataTestId} theme={theme}>
      <SecondaryButton
        onClick={handlePrev}
        disabled={currentPage === 1 || isDisabled}
        dataTestId={`${dataTestId}-prev-button`}
        sx={PaginationButtonStyles(theme)}
      >
        <ArrowBackIosNewIcon
          sx={PaginationButtonIconStyles(theme, currentPage === totalPages || isDisabled)}
        />
      </SecondaryButton>
      <PaginationLocationTextContainer>
        <PaginationLocationText data-testid={`${dataTestId}-page-number`}>
          {currentPage}
        </PaginationLocationText>
        <PaginationLocationText>of</PaginationLocationText>
        <PaginationLocationText data-testid={`${dataTestId}-total-pages-number`}>
          {totalPages}
        </PaginationLocationText>
      </PaginationLocationTextContainer>
      <SecondaryButton
        onClick={handleNext}
        disabled={currentPage === totalPages || isDisabled}
        isLoading={isLoading}
        dataTestId={`${dataTestId}-next-button`}
        sx={PaginationButtonStyles(theme)}
      >
        <ArrowForwardIosIcon
          sx={PaginationButtonIconStyles(theme, currentPage === totalPages || isDisabled)}
        />
      </SecondaryButton>
    </PaginationButtonGroupContainer>
  );
};
