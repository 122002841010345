import { Box, Dialog, Typography, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CloseButton } from 'sharedComponents/Buttons';
import { CandidateFeedbackModalProps } from '../types';
import { mockFeedbackData } from '../mockData';
import { ModalContainer, Rows, Title } from './styles';
import { CandidateFeedbackRow } from './CandidateFeedbackRow';

export const CandidateFeedbackModal: React.FC<CandidateFeedbackModalProps> = ({
  candidateName,
  open,
  onClose,
  roleTitle,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.MODAL}
      fullWidth={true}
      maxWidth="lg"
      onClose={onClose}
      open={open}
      sx={{
        color: theme.palette.text.primary,
      }}
    >
      <ModalContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title
            data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.MODAL_TITLE}
            variant="heading7"
          >
            Scorecards for {candidateName}
          </Title>
          <CloseButton onClick={onClose} />
        </Box>
        <Typography data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.ROLE_TITLE}>
          {roleTitle}
        </Typography>
        <Rows>
          {mockFeedbackData.map((data) => (
            <CandidateFeedbackRow data={data} key={data.id} />
          ))}
        </Rows>
      </ModalContainer>
    </Dialog>
  );
};
