import { CandidateFeedbackFinalRecommendation } from '../enum';
import { FinalRecommendation, FinalRecommendationDataReturned } from '../types';

export const getFinalRecommendationData = (
  finalRecommendation: FinalRecommendation
): FinalRecommendationDataReturned => {
  if (finalRecommendation === CandidateFeedbackFinalRecommendation.MAYBE) {
    return {
      alertType: 'warning',
      text: 'Maybe recommend',
    };
  } else if (finalRecommendation === CandidateFeedbackFinalRecommendation.YES) {
    return {
      alertType: 'success',
      text: 'Recommended',
    };
  } else {
    return {
      alertType: 'error',
      text: 'Do not recommend',
    };
  }
};
