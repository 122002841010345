import { Box, useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { PaginationWithArrowsProps } from './types';
import {
  PaginationButtonGroupContainer,
  PaginationButtonIconStyles,
  PaginationButtonStyles,
  PaginationLocationText,
  PaginationLocationTextContainer,
} from './styles';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
export const PaginationButtonGroup: React.FC<PaginationWithArrowsProps> = ({
  totalPages,
  currentPage,
  onChange,
  dataTestId,
  isDisabled,
}) => {
  const { isLoading } = useConnectContext();
  const theme = useTheme();
  const handlePrev = () => {
    if (currentPage > 1) onChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onChange(currentPage + 1);
  };

  const isButtonDisabled = (buttonDirection: 'prev' | 'next') => {
    if (buttonDirection === 'prev') return currentPage === 1 || isDisabled;
    if (buttonDirection === 'next') return currentPage === totalPages || isDisabled;
    return false;
  };

  return (
    <PaginationButtonGroupContainer data-testid={dataTestId} theme={theme}>
      <PaginationLocationTextContainer>
        <PaginationLocationText data-testid={`${dataTestId}-page-number`}>
          {currentPage}
        </PaginationLocationText>
        <PaginationLocationText>of</PaginationLocationText>
        <PaginationLocationText data-testid={`${dataTestId}-total-pages-number`}>
          {totalPages}
        </PaginationLocationText>
      </PaginationLocationTextContainer>
      <Box display="flex" gap={theme.spacing(1)}>
        <SecondaryButton
          onClick={handlePrev}
          disabled={isButtonDisabled('prev')}
          dataTestId={`${dataTestId}-prev-button`}
          sx={PaginationButtonStyles(theme)}
        >
          <ArrowBackIosNewIcon sx={PaginationButtonIconStyles(theme, isButtonDisabled('prev'))} />
        </SecondaryButton>
        <SecondaryButton
          onClick={handleNext}
          disabled={isButtonDisabled('next')}
          isLoading={isLoading}
          dataTestId={`${dataTestId}-next-button`}
          sx={PaginationButtonStyles(theme)}
        >
          <ArrowForwardIosIcon sx={PaginationButtonIconStyles(theme, isButtonDisabled('next'))} />
        </SecondaryButton>
      </Box>
    </PaginationButtonGroupContainer>
  );
};
