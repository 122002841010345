import { CandidatesListContainer } from '../styles';
import { ConnectCandidateRow } from './ConnectCandidateRow';
import { ConnectCandidatesListProps } from '../types';
import { Box, Link, Typography, useTheme } from '@mui/material';
import auth from 'utils/auth';

export const ConnectCandidatesList: React.FC<ConnectCandidatesListProps> = ({ results }) => {
  const theme = useTheme();

  if (results.length === 0) {
    const candidatesListLink = auth.isSchoolAdmin() ? '/school/candidates' : '/district/candidates';

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ paddingTop: theme.spacing(2) }}>
          You have no hiring requests at the moment
        </Typography>

        <Link sx={{ paddingTop: theme.spacing(1) }} href={candidatesListLink}>
          View all candidates
        </Link>
      </Box>
    );
  }

  return (
    <CandidatesListContainer>
      {results.map((detail) => (
        <ConnectCandidateRow
          actions={{
            scorecard: false,
            reference: false,
            application: false,
          }}
          key={detail.candidate.id}
          candidate={detail.candidate}
          requestor={detail.requester}
          role={detail.role}
          created={detail.created}
          notesCount={detail.notes_count}
        />
      ))}
    </CandidatesListContainer>
  );
};
