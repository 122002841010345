import { Box } from '@mui/material';
import moment from 'moment';

import { Bottom, CandidateFeedbackRowContainer, Top, TopLeft } from './styles';
import { CandidateAndSubmitter } from './CandidateAndSubmitter';
import { CandidateFeedbackRowProps } from '../types';
import { CompletionChip } from './CompletionChip';
import { Recommendation } from './Recommendation';
import { RowCTA } from './RowCTA';
import { TemplateAndVisibility } from './TemplateAndVisibility';

export const CandidateFeedbackRow: React.FC<CandidateFeedbackRowProps> = ({ data }) => {
  const {
    submitted_by: submittedBy,
    created,
    scorecard_complete: isScorecardComplete,
    template,
    show_answer_on_preview: showAnswerOnPreview,
  } = data;

  const isComplete = isScorecardComplete;
  const amAuthorOfFeedback = true;
  const date = created;

  const formattedDate = moment(date).format('M/D/YY [at] h:mma');
  const completionText = isComplete
    ? `Completed on ${formattedDate}`
    : `Started on ${formattedDate}`;

  return (
    <CandidateFeedbackRowContainer>
      <Top>
        <TopLeft>
          <CompletionChip isComplete={isComplete} />
          <Box sx={{ width: '100%' }}>
            <CandidateAndSubmitter
              completionText={completionText}
              isComplete={isComplete}
              submittedBy={submittedBy}
            />

            <Bottom>
              {showAnswerOnPreview && isComplete && (
                <Recommendation
                  completionText={completionText}
                  cumulativeScore={data.cumulative_score}
                  finalRecommendation={data.final_recommendation}
                  maxCumulativeScore={data.max_cumulative_score}
                  showTotalOnPreview={data.show_total_on_preview}
                />
              )}
              <TemplateAndVisibility template={template} />
            </Bottom>
          </Box>
        </TopLeft>
        {amAuthorOfFeedback && <RowCTA isComplete={isComplete} />}
      </Top>
    </CandidateFeedbackRowContainer>
  );
};
