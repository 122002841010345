import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { CandidateFeedbackModal } from './CandidateFeedback/components/CandidateFeedbackModal';
import { CandidateRow } from '../styles';
import { CandidateRowActions } from './Actions/CandidateRowActions';
import { ConnectCandidateRowProps } from '../types';
import { RowCandidateAndRequestorInfo } from './RowCandidateAndRequestorInfo';
import { RowJobInfo } from './RowJobInfo';

export const ConnectCandidateRow: React.FC<ConnectCandidateRowProps> = ({
  actions,
  candidate,
  created,
  notesCount,
  requestor,
  role,
}) => {
  const candidateName = `${candidate.first_name} ${candidate.last_name}`;

  return (
    <CandidateRow data-testid={ATSConnectHiringRequestTestIds.CANDIDATE_ROW}>
      <RowCandidateAndRequestorInfo
        actions={{
          scorecard: false,
          reference: false,
          application: false,
        }}
        candidate={candidate}
        requestor={requestor}
        created={created}
      />
      <RowJobInfo role={role} notesCount={notesCount} />
      <CandidateRowActions actions={actions} />
      <CandidateFeedbackModal
        candidateName={candidateName}
        open={true}
        onClose={() => {}}
        roleTitle={role.title}
      />
    </CandidateRow>
  );
};
