import { Box, styled } from '@mui/material';
import {
  ConnectCandidateMatchCardSchoolSectionDataTestIds,
  ConnectMoreInfoSliderDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { MatchCardSchoolSectionHeader } from './SchoolSectionHeader';
import { PrincipalComponent } from 'features/Connect/components/PrincipalComponent/PrincipalComponent';
import { SchoolInfoHighlightRow } from 'features/Connect/components/HighlightRows';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';
import { VacancyAccordionRow } from 'features/Connect/components/VacancySection/VacancySectionAccordionRow';
import { CandidateMatchCardInterface } from 'features/Connect/features/ConnectCandidatePortalDashboards/types';
import { Vacancy } from 'types/connectTypes';
import { useEffect, useState } from 'react';
import { CandidateMatchCardJobPostingsLimit } from 'features/Connect/features/ConnectCandidatePortalDashboards/constants';
export const MatchCardSchoolSection: React.FC<CandidateMatchCardInterface> = ({
  school,
  principal,
  isLoading = false,
  vacancies,
}) => {
  const [vacanciesToShow, setVacanciesToShow] = useState<Vacancy[]>([]);

  useEffect(() => {
    setVacanciesToShow(vacancies?.slice(0, CandidateMatchCardJobPostingsLimit));
  }, [vacancies]);

  return (
    <SchoolCardContainer>
      <SchoolHeaderContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_HEADER_CONTAINER}
      >
        <MatchCardSchoolSectionHeader school={school} />
        <PrincipalComponent principal={principal} />
      </SchoolHeaderContainer>
      <SchoolInfoContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_INFO_CONTAINER}
      >
        <SchoolInfoHighlightRow school={school} />
      </SchoolInfoContainer>
      <RoleListContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_ROLES_LIST_CONTAINER}
      >
        {isLoading ? (
          <LoadingSpinner dataTestId={ConnectMoreInfoSliderDataTestIds.LOADING_SPINNER} />
        ) : (
          <VacancyAccordionRow school={school} vacancies={vacanciesToShow} isMatchCard={true} />
        )}
      </RoleListContainer>
    </SchoolCardContainer>
  );
};

const SchoolCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1.5),
}));

const SchoolHeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0.5),
  },
}));

const SchoolInfoContainer = styled(Box)(() => ({
  width: '100%',
}));

const RoleListContainer = styled(Box)(() => ({
  width: '100%',
}));
