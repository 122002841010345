import { Box, useTheme } from '@mui/material';

import { Alert } from 'sharedComponents/Alert';
import { AlertContent, CompletionText, RecommendationText, ScoreAndCompletion } from './styles';
import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { getFinalRecommendationData } from './utils';
import { RecommendationProps } from '../types';

export const Recommendation: React.FC<RecommendationProps> = ({
  completionText,
  cumulativeScore,
  finalRecommendation,
  maxCumulativeScore,
  showTotalOnPreview,
}) => {
  const theme = useTheme();
  const finalRecommendationToRender = getFinalRecommendationData(finalRecommendation);

  return (
    <Alert
      dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.RECOMMENDATION_SECTION}
      type={finalRecommendationToRender.alertType}
      sx={{
        width: '100%',
        marginTop: theme.spacing(2),
        '.MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <AlertContent>
        <RecommendationText
          data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.OVERALL_RECOMMENDATION_TEXT}
        >
          {finalRecommendationToRender.text}
        </RecommendationText>
        <ScoreAndCompletion>
          {showTotalOnPreview && (
            <Box
              data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.TOTAL_SCORE}
              sx={{ fontWeight: theme.typography.fontWeightMediumBold }}
            >
              -- Total score: {cumulativeScore} / {maxCumulativeScore} &nbsp;
              <CompletionText
                data-testid={
                  ATSConnectHiringRequestCandidateFeedbackTestIds.RECOMMENDATION_COMPLETED_TEXT
                }
              >
                {completionText}
              </CompletionText>
            </Box>
          )}
        </ScoreAndCompletion>
      </AlertContent>
    </Alert>
  );
};
