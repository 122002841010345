export const CandidateFeedbackFinalRecommendation = {
  YES: 'final_recommendation/yes',
  MAYBE: 'final_recommendation/maybe',
  NO: 'final_recommendation/no',
} as const;

export const CandidateFeedbackModalTypes = {
  SCORECARD: 'scorecard',
  REFERENCE: 'reference',
} as const;
