export const PROD_URL = 'app.hirenimble.com';
export const STAGING_URL = 'dev.hirenimble.com';
export const TEST_URL = 'test.hirenimble.com';
export const LOCAL_URL = 'localhost';

export const NAVBAR_BUTTON_LABELS = {
  LOGIN: 'Login',
  SIGN_UP: 'Sign up',
  DESKTOP_SUPPORT: 'Get Support',
  MOBILE_SUPPORT: 'Support',
};
